<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-12" id="firstRow">
          <div class="p-0 row">
            <!-- row 1 -->
            <div class="col-md-12">
              <div class="alert alert-danger">
                <router-link :to="'/visit-tracker' + filterUrl"
                  ><span class="badge badge-danger">{{ missedVisits }}</span>
                  Missed visits from {{ lastYearDate }} to
                  {{ today }}</router-link
                >
              </div>
            </div>
            <div
              class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
            >
              <div class="widget widget-chart-one">
                <div class="widget-heading">
                  <h5 class="">VISIT REPORTS</h5>
                  <ul class="tabs tab-pills">
                    <li>
                      <a href="javascript:void(0);" id="tb_1" class="tabmenu"
                        >Monthly</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="widget-content">
                  <div class="tabs tab-content">
                    <div
                      class="p-2 text-center"
                      v-if="loadingCRM && loadingMICH && loadingGERO"
                    >
                      <b-card class="">
                        <b-skeleton animation="wave" width="85%"></b-skeleton>
                        <b-skeleton animation="wave" width="65%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                        <b-skeleton animation="wave" width="95%"></b-skeleton>
                        <b-skeleton animation="wave" width="79%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                        <b-skeleton animation="wave" width="86%"></b-skeleton>
                        <b-skeleton animation="wave" width="78%"></b-skeleton>
                        <b-skeleton animation="wave" width="98%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                        <b-skeleton animation="wave" width="92%"></b-skeleton>
                        <b-skeleton animation="wave" width="95%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                        <b-skeleton animation="wave" width="95%"></b-skeleton>
                      </b-card>
                    </div>
                    <div id="content_1" class="tabcontent" v-else>
                      <apexchart
                        type="area"
                        height="500"
                        :options="areachartOptions"
                        :series="areaseries"
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
            >
              <div class="row">
                <div class="col-12">
                  <div class="mb-3 widget widget-table-one">
                    <div class="widget-heading">
                      <h5 class="">Visits Target</h5>
                    </div>
                    <div
                      class="p-2 text-center"
                      v-if="loadingCRM && loadingMICH && loadingGERO"
                    >
                      <b-card class="">
                        <b-skeleton animation="wave" width="85%"></b-skeleton>
                      </b-card>
                    </div>
                    <div class="widget-content" v-else>
                      <div class="transactions-list">
                        <div class="t-item">
                          <div class="t-company-name">
                            <div class="t-icon">
                              <div
                                class="icon"
                                style="background-color: #6dda84"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  style="color: #170f03"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-crosshair"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="22" y1="12" x2="18" y2="12"></line>
                                  <line x1="6" y1="12" x2="2" y2="12"></line>
                                  <line x1="12" y1="6" x2="12" y2="2"></line>
                                  <line x1="12" y1="22" x2="12" y2="18"></line>
                                </svg>
                              </div>
                            </div>
                            <div class="t-name">
                              <h4>{{ currentMonth }}, {{ currentYear }}</h4>
                              <p class="meta-date">
                                {{ visitsThisMonth }} visits
                              </p>
                            </div>
                          </div>
                          <div class="t-rate rate-dec">
                            <p>
                              <span>{{ targetPercent }}% of Target </span>

                              <svg
                                style="
                                  width: 30px;
                                  height: 30px;
                                  vertical-align: middle;
                                  color: #3d9850;
                                "
                                v-if="visitsThisMonth > 500"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trending-up"
                              >
                                <polyline
                                  points="23 6 13.5 15.5 8.5 10.5 1 18"
                                ></polyline>
                                <polyline points="17 6 23 6 23 12"></polyline>
                              </svg>

                              <svg
                                v-else
                                style="
                                  width: 30px;
                                  height: 30px;
                                  vertical-align: middle;
                                "
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trending-down"
                              >
                                <polyline
                                  points="23 18 13.5 8.5 8.5 13.5 1 6"
                                ></polyline>
                                <polyline points="17 18 23 18 23 12"></polyline>
                              </svg>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="widget widget-table-one" id="upcomingprojects">
                    <div class="widget-heading">
                      <h5 class="">Ongoing Projects</h5>
                      <button
                        class="float-right ml-auto badge badge-success"
                        id="downloadupcomingprojects"
                      >
                        <i class="fa fa-download"></i>
                      </button>
                    </div>
                    <div
                      class="p-2 text-center"
                      v-if="loadingCRM && loadingMICH && loadingGERO"
                    >
                      <b-card class="">
                        <b-skeleton animation="wave" width="85%"></b-skeleton>
                        <b-skeleton animation="wave" width="65%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                        <b-skeleton animation="wave" width="95%"></b-skeleton>
                        <b-skeleton animation="wave" width="79%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                        <b-skeleton animation="wave" width="86%"></b-skeleton>
                        <b-skeleton animation="wave" width="78%"></b-skeleton>
                        <b-skeleton animation="wave" width="98%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                        <b-skeleton animation="wave" width="78%"></b-skeleton>
                        <b-skeleton animation="wave" width="98%"></b-skeleton>
                        <b-skeleton animation="wave" width="90%"></b-skeleton>
                      </b-card>
                    </div>
                    <div
                      class="widget-content upcomingproj"
                      id="upcomingProjectsWrapper"
                      v-else
                    >
                      <div
                        class="transactions-list"
                        :style="`border-left: 15px solid #${Math.floor(
                          Math.random() * 16777215
                        ).toString(16)}`"
                      >
                        <div class="t-item">
                          <div class="t-company-name">
                            <div class="t-icon"></div>
                            <div class="t-name">
                              <h4>All Patients</h4>
                              <p class="pt-1 meta-date small">All Patients</p>
                            </div>
                          </div>
                          <div class="t-rate rate-dec">
                            <p
                              class="text-right text-dark font-weight-bolder"
                              style="font-size: larger"
                            >
                              <span>{{
                                patientsCount + michCount * 2 + 140000
                              }}</span>
                            </p>
                            <small>Patients</small>
                          </div>
                        </div>
                      </div>

                      <div class="w-100">
                        <hr style="border-top: 1px solid green" />
                      </div>

                      <div
                        class="transactions-list"
                        :style="`border-left: 15px solid #${Math.floor(
                          Math.random() * 16777215
                        ).toString(16)}`"
                      >
                        <div class="t-item">
                          <div class="t-company-name">
                            <div class="t-icon"></div>
                            <div class="t-name">
                              <h4>Ganar</h4>
                              <p class="pt-1 meta-date small">
                                Doctors on Ganar
                              </p>
                            </div>
                          </div>
                          <div class="t-rate rate-dec">
                            <p
                              class="text-right text-dark font-weight-bolder"
                              style="font-size: larger"
                            >
                              <span>
                                {{ doctorsCount }}
                              </span>
                            </p>
                            <small>Doctors</small>
                          </div>
                        </div>
                      </div>

                      <div
                        class="transactions-list"
                        :style="`border-left: 15px solid #${Math.floor(
                          Math.random() * 16777215
                        ).toString(16)}`"
                      >
                        <div class="t-item">
                          <div class="t-company-name">
                            <div class="t-icon"></div>
                            <div class="t-name">
                              <h4>Private Patients</h4>
                              <p class="pt-1 meta-date small">
                                Patients Under Private Hospitals
                              </p>
                            </div>
                          </div>
                          <div class="t-rate rate-dec">
                            <p
                              class="text-right text-dark font-weight-bolder"
                              style="font-size: larger"
                            >
                              <span> 0</span>
                            </p>
                            <small>Patients</small>
                          </div>
                        </div>
                      </div>

                      <div
                        class="transactions-list"
                        v-for="client in clients"
                        :key="client.id"
                        :style="`border-left: 15px solid #${Math.floor(
                          Math.random() * 16777215
                        ).toString(16)}`"
                      >
                        <div class="t-item">
                          <div class="t-company-name">
                            <div class="t-icon"></div>
                            <div class="t-name">
                              <router-link
                                :to="`/corportate/clients/members/${client.id}`"
                              >
                                <h4>{{ client.name }}</h4>
                              </router-link>
                              <p class="pt-1 meta-date small">
                                {{ formatFancyDate(client.created_at) }}
                              </p>
                            </div>
                          </div>
                          <div class="t-rate rate-dec">
                            <p
                              class="text-right text-dark font-weight-bolder"
                              style="font-size: larger"
                            >
                              <span>{{ client.patients_count }}</span>
                            </p>
                            <small>Patients</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ongoing projects ctd -->
            <div class="col-12" id="ongoingprojectsctd">
              <div class="row">
                <div
                  class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
                >
                  <div class="widget widget-chart-one">
                    <div class="widget-heading">
                      <h5 class="">Gerocare Home Hospital</h5>
                    </div>

                    <div class="widget-content">
                      <div class="widget-table-one">
                        <div
                          class="p-2 text-center"
                          v-if="loadingCRM && loadingMICH && loadingGERO"
                        >
                          <b-card class="">
                            <b-skeleton
                              animation="wave"
                              width="85%"
                            ></b-skeleton>
                            <b-skeleton
                              animation="wave"
                              width="65%"
                            ></b-skeleton>
                          </b-card>
                        </div>
                        <div class="widget-content" v-else>
                          <div
                            class="transactions-list"
                            :style="`border-left: 15px solid #${Math.floor(
                              Math.random() * 16777215
                            ).toString(16)}`"
                          >
                            <div class="t-item">
                              <div class="t-company-name">
                                <div class="t-icon"></div>
                                <div class="t-name">
                                  <h4>GeroCare Elderlies</h4>
                                  <p class="pt-1 meta-date small">
                                    All GeroCare Elderlies
                                  </p>
                                </div>
                              </div>
                              <div class="t-rate rate-dec">
                                <p
                                  class="text-right text-dark font-weight-bolder"
                                  style="font-size: larger"
                                >
                                  <span>{{ patientsCount - hygeiaCount }}</span>
                                </p>
                                <small>Elderlies</small>
                              </div>
                            </div>
                          </div>

                          <div
                            class="transactions-list"
                            :style="`border-left: 15px solid #${Math.floor(
                              Math.random() * 16777215
                            ).toString(16)}`"
                          >
                            <div class="t-item">
                              <div class="t-company-name">
                                <div class="t-icon"></div>
                                <div class="t-name">
                                  <h4>Hygeia HMO</h4>
                                  <p class="pt-1 meta-date small">
                                    On demand visits
                                  </p>
                                </div>
                              </div>
                              <div class="t-rate rate-dec">
                                <p
                                  class="text-right text-dark font-weight-bolder"
                                  style="font-size: larger"
                                >
                                  <span>{{ hygeiaCount }}</span>
                                </p>
                                <small>Patients</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
                >
                  <div class="widget widget-chart-one">
                    <div class="widget-heading">
                      <h5 class="">Gerocare Bespoke Plans</h5>
                    </div>

                    <div class="widget-content">
                      <div class="widget-table-one">
                        <div
                          class="p-2 text-center"
                          v-if="loadingCRM && loadingMICH && loadingGERO"
                        >
                          <b-card class="">
                            <b-skeleton
                              animation="wave"
                              width="85%"
                            ></b-skeleton>
                            <b-skeleton
                              animation="wave"
                              width="65%"
                            ></b-skeleton>
                          </b-card>
                        </div>
                        <div class="widget-content" v-else>
                          <div
                            class="transactions-list"
                            :style="`border-left: 15px solid #${Math.floor(
                              Math.random() * 16777215
                            ).toString(16)}`"
                          >
                            <div class="t-item">
                              <div class="t-company-name">
                                <div class="t-icon"></div>
                                <div class="t-name">
                                  <h4>Mother Infant Child Health Program</h4>
                                  <p class="pt-1 meta-date small">
                                    Lagos State Government
                                  </p>
                                </div>
                              </div>
                              <div class="t-rate rate-dec">
                                <p
                                  class="text-right text-dark font-weight-bolder"
                                  style="font-size: larger"
                                >
                                  <span>{{ michCount }}</span>
                                </p>
                                <small>Beneficiaries</small>
                              </div>
                            </div>
                          </div>

                          <div
                            class="transactions-list"
                            :style="`border-left: 15px solid #${Math.floor(
                              Math.random() * 16777215
                            ).toString(16)}`"
                          >
                            <div class="t-item">
                              <div class="t-company-name">
                                <div class="t-icon"></div>
                                <div class="t-name">
                                  <h4>Lagos State Elderly Care Plan</h4>
                                  <p class="pt-1 meta-date small">
                                    Lagos State Government
                                  </p>
                                </div>
                              </div>
                              <div class="t-rate rate-dec">
                                <p
                                  class="text-right text-dark font-weight-bolder"
                                  style="font-size: larger"
                                >
                                  <span>{{ 2000 }}</span>
                                </p>
                                <small>Beneficiaries</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
                >
                  <div class="widget widget-chart-one">
                    <div class="widget-heading">
                      <h5 class="">Gerocare Rural Care</h5>
                      <!-- <button
                                    class="float-right ml-auto badge badge-success"
                                    id="downloadongoingprojects">
                                      <i class="fa fa-download"></i>
                                    </button> -->
                    </div>

                    <div class="widget-content">
                      <div class="widget-table-one">
                        <div
                          class="p-2 text-center"
                          v-if="loadingCRM && loadingMICH && loadingGERO"
                        >
                          <b-card class="">
                            <b-skeleton
                              animation="wave"
                              width="85%"
                            ></b-skeleton>
                            <b-skeleton
                              animation="wave"
                              width="65%"
                            ></b-skeleton>
                          </b-card>
                        </div>
                        <div class="widget-content" v-else>
                          <div
                            class="transactions-list"
                            :style="`border-left: 15px solid #${Math.floor(
                              Math.random() * 16777215
                            ).toString(16)}`"
                          >
                            <div class="t-item">
                              <div class="t-company-name">
                                <div class="t-icon"></div>
                                <div class="t-name">
                                  <h4>Medra</h4>
                                  <p class="pt-1 meta-date small">
                                    Medra Communities
                                  </p>
                                </div>
                              </div>
                              <div class="t-rate rate-dec">
                                <p
                                  class="text-right text-dark font-weight-bolder"
                                  style="font-size: larger"
                                >
                                  <span> 2</span>
                                </p>
                                <small>Communities</small>
                              </div>
                            </div>
                          </div>

                          <div
                            class="transactions-list"
                            :style="`border-left: 15px solid #${Math.floor(
                              Math.random() * 16777215
                            ).toString(16)}`"
                          >
                            <div class="t-item">
                              <div class="t-company-name">
                                <div class="t-icon"></div>
                                <div class="t-name">
                                  <h4>Farmers Healthcare Scheme</h4>
                                  <p class="pt-1 meta-date small">
                                    Farmers Healthcare Scheme
                                  </p>
                                </div>
                              </div>
                              <div class="t-rate rate-dec">
                                <p
                                  class="text-right text-dark font-weight-bolder"
                                  style="font-size: larger"
                                >
                                  <span> 140000 </span>
                                </p>
                                <small>Farmers</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- row 2 -->
        <div
          class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget widget-chart-one">
            <div class="widget-heading">
              <h5 class="">FLOW CHART</h5>
            </div>

            <div class="widget-content">
              <div class="tabs tab-content">
                <div
                  class="p-2 text-center"
                  v-if="loadingCRM && loadingMICH && loadingGERO"
                >
                  <b-card class="">
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="65%"></b-skeleton>
                    <b-skeleton animation="wave" width="90%"></b-skeleton>
                    <b-skeleton animation="wave" width="95%"></b-skeleton>
                    <b-skeleton animation="wave" width="79%"></b-skeleton>
                    <b-skeleton animation="wave" width="90%"></b-skeleton>
                    <b-skeleton animation="wave" width="86%"></b-skeleton>
                    <b-skeleton animation="wave" width="78%"></b-skeleton>
                    <b-skeleton animation="wave" width="98%"></b-skeleton>
                    <b-skeleton animation="wave" width="90%"></b-skeleton>
                    <b-skeleton animation="wave" width="92%"></b-skeleton>
                    <b-skeleton animation="wave" width="95%"></b-skeleton>
                    <b-skeleton animation="wave" width="90%"></b-skeleton>
                    <b-skeleton animation="wave" width="95%"></b-skeleton>
                  </b-card>
                </div>
                <div id="content_1" class="text-center tabcontent" v-else>
                  <!-- directive -->
                  <div class="images" v-viewer>
                    <img
                      src="../../../assets/flowchart.png"
                      class="img-fluid w-100"
                      alt=""
                      srcset=""
                    />
                  </div>

                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#flowChartModal"
                    class="mt-2 btn btn-primary"
                  >
                    <i class="fa fa-info"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget widget-table-one">
            <div class="widget-heading">
              <h5 class="">VISIT CYCLES</h5>
            </div>
            <div
              class="p-2 text-center"
              v-if="loadingCRM && loadingMICH && loadingGERO"
            >
              <b-card class="">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="65%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="79%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="86%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
              </b-card>
            </div>
            <div class="tabcontent" v-else>
              <apexchart
                type="bar"
                height="350"
                :options="cyclechartOptions"
                :series="cycleseries"
              ></apexchart>
            </div>
          </div>
        </div>

        <!-- row 3 -->
        <div
          class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget widget-chart-two">
            <div class="widget-heading">
              <h5 class="">All-time Monthly Visits Summary</h5>
            </div>
            <div
              class="p-2 text-center"
              v-if="loadingCRM && loadingMICH && loadingGERO"
            >
              <b-card class="">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="65%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="79%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="86%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
              </b-card>
            </div>
            <div class="widget-content" style="position: relative" v-else>
              <apexchart
                type="polarArea"
                :options="polarchartOptions"
                :series="polarseries"
              ></apexchart>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
          <div class="widget widget-account-invoice-one">
            <div class="widget-heading">
              <h5 class="">Calls To Add Elderly</h5>
            </div>
            <div
              class="p-2 text-center"
              v-if="loadingCRM && loadingMICH && loadingGERO"
            >
              <b-card class="">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="65%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="79%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="86%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
              </b-card>
            </div>
            <div class="widget-content" v-else>
              <apexchart
                type="donut"
                :options="addElderlyCallschartOptions"
                :series="addElderlyCallsseries"
              ></apexchart>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 layout-spacing">
          <div class="widget widget-account-invoice-one">
            <div class="widget-heading">
              <h5 class="">Calls To Prospects</h5>
            </div>
            <div
              class="p-2 text-center"
              v-if="loadingCRM && loadingMICH && loadingGERO"
            >
              <b-card class="">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="65%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="79%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="86%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
              </b-card>
            </div>
            <div class="widget-content" v-else>
              <apexchart
                type="donut"
                :options="callToProspectchartOptions"
                :series="callToProspectseries"
              ></apexchart>
            </div>
          </div>
        </div>

        <!-- row 5 -->
        <div class="col-12 layout-spacing">
          <div class="widget widget-account-invoice-one">
            <div class="widget-heading">
              <h5 class="">Medical Requests</h5>
            </div>
            <div
              class="p-2 text-center"
              v-if="loadingCRM && loadingMICH && loadingGERO"
            >
              <b-card class="">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="65%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="95%"></b-skeleton>
                <b-skeleton animation="wave" width="79%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="86%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
                <b-skeleton animation="wave" width="78%"></b-skeleton>
                <b-skeleton animation="wave" width="98%"></b-skeleton>
                <b-skeleton animation="wave" width="90%"></b-skeleton>
              </b-card>
            </div>
            <div class="widget-content" v-else>
              <apexchart
                type="bar"
                height="350"
                :options="barchartOptions"
                :series="barseries"
              ></apexchart>
            </div>
          </div>
        </div>

        <!-- row 6 -->
        <div
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget widget-table-two">
            <div class="widget-heading">
              <h5 class="">Medical Service Daily Log</h5>
              <input
                type="date"
                name="date"
                id="date"
                class="pull-right form-control"
                v-model="dailyLog"
                @change="fetchMedicalServiceLogApp"
              />
            </div>
            <div v-if="loadingCRM && loadingMICH && loadingGERO">
              <b-skeleton-table
                :rows="10"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
            <div class="widget-content" v-else>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th><div class="th-content">Date</div></th>
                      <th>
                        <div class="th-content">Overall Missed Visits</div>
                      </th>
                      <th><div class="th-content">Visits Yesterday</div></th>
                      <th>
                        <div class="th-content">Missed Visits Yesterday</div>
                      </th>
                      <th><div class="th-content">Reports Submitted</div></th>
                      <th>
                        <div class="th-content">Medical Request raised</div>
                      </th>
                      <th><div class="th-content">New Subscriptions</div></th>
                      <th><div class="th-content">Resubscriptions</div></th>
                      <th>
                        <div class="th-content">New Doctors Onboarded</div>
                      </th>
                      <th>
                        <div class="th-content">Incomplete Onboarding</div>
                      </th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td>
                        <div class="td-content">
                          {{ yesterday }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          {{ gerocareDailyLog.overall_missed_visits }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          <span class="">{{
                            gerocareDailyLog.visits_yesterday
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          <span class="">{{
                            gerocareDailyLog.missed_visits_yesterday
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          {{ gerocareDailyLog.reports_submitted_yesterday }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          {{ yesterdaysPrescription }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          {{ gerocareDailyLog.new_subscriptions }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          {{ gerocareDailyLog.resubscriptions }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          {{ gerocareDailyLog.new_doctors }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          {{ gerocareDailyLog.incomplete_onboarding }}
                        </div>
                      </td>
                      <td>
                        <RouterLink
                          :to="{
                            path: `daily-remarks`,
                            query: { date: dailyLog },
                          }"
                          :class="'btn btn-primary'"
                        >
                          <i class="fa fa-eye"></i>
                        </RouterLink>
                        <button
                          type="button"
                          class="mt-1 btn btn-primary"
                          data-toggle="modal"
                          data-target="#RemarkModal"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- row 7 -->
        <div
          class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget widget-table-two">
            <div class="widget-heading">
              <h5 class="">Recent Medical Requests - Unpaid</h5>
            </div>
            <div v-if="loadingCRM && loadingMICH && loadingGERO">
              <b-skeleton-table
                :rows="10"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div class="widget-content" v-else>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th><div class="th-content">Elderly</div></th>
                      <th><div class="th-content">Sponsor</div></th>
                      <th><div class="th-content th-heading">Amount</div></th>
                      <th><div class="th-content">Delivery Status</div></th>
                      <!-- <th><div class="th-content">Invoice</div></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in recentUnpaidPrescriptions"
                      :key="item.id"
                    >
                      <td>
                        <div class="td-content customer-name">
                          {{ item.elderly_name }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content product-brand">
                          {{ item.sponsor_name }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content pricing">
                          <span class="">{{
                            formatMoney(item.overall_total)
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          <span
                            class="badge badge-success"
                            v-if="item.delivery_status == 'delivered'"
                            >Delivered</span
                          >
                          <span
                            class="badge badge-dark"
                            v-if="item.delivery_status == 'not delivered'"
                            >Not Delivered</span
                          >
                        </div>
                      </td>
                      <!-- <td><div class="td-content"><button @click="showPdf(item.invoice.filename)" class="badge outline-badge-primary"><i class="fa fa-eye"></i></button></div></td>  -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
        >
          <div class="widget widget-table-two">
            <div class="widget-heading">
              <h5 class="">Recent Medical Requests - Paid</h5>
            </div>
            <div v-if="loadingCRM && loadingMICH && loadingGERO">
              <b-skeleton-table
                :rows="10"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
            <div class="widget-content" v-else>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th><div class="th-content">Elderly</div></th>
                      <th><div class="th-content">Sponsor</div></th>
                      <th><div class="th-content th-heading">Amount</div></th>
                      <th><div class="th-content">Delivery Status</div></th>
                      <!-- <th><div class="th-content">Invoice</div></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in recentPaidPrescriptions" :key="item.id">
                      <td>
                        <div class="td-content customer-name">
                          {{ item.elderly_name }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content product-brand">
                          {{ item.sponsor_name }}
                        </div>
                      </td>
                      <td>
                        <div class="td-content pricing">
                          <span class="">{{
                            formatMoney(item.overall_total)
                          }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="td-content">
                          <span
                            class="badge badge-success"
                            v-if="item.delivery_status == 'delivered'"
                            >Delivered</span
                          >
                          <span
                            class="badge badge-dark"
                            v-if="item.delivery_status == 'not delivered'"
                            >Not delivered</span
                          >
                        </div>
                      </td>
                      <!-- <td><div class="td-content"><span class="badge outline-badge-primary"><i class="fa fa-eye"></i></span></div></td>  -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="flowChartModal"
          role="dialog"
          aria-labelledby="flowChartModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="flowChartModalLabel">Flow Chart</h5>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <div class="container mt-container">
                      <ul class="pl-0 modern-timeline">
                        <li>
                          <div class="modern-timeline-badge"></div>
                          <div class="modern-timeline-panel">
                            <div class="text-center modern-timeline-preview">
                              <p class="m-3">
                                Extracts medical request - drugs, test,
                                specialist care etc and sends to relevant vendor
                                to get costing
                              </p>
                            </div>
                            <div class="text-center modern-timeline-body">
                              <h4 class="mb-4">Medical Services</h4>
                              <p class="mb-4">
                                Go through recent visit reports and check if
                                there are any medical requests - drugs, tests,
                                specialist care, physiotherapy etc If yes, they
                                are sent to the corresponding vendor for
                                costing.... If no, visit cycle is marked
                                closed/completed
                              </p>
                              <p>
                                <a
                                  href="javascript:void(0);"
                                  class="mt-2 btn btn-primary"
                                  >Step 1</a
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="modern-timeline-badge"></div>
                          <div class="modern-timeline-panel">
                            <div class="text-center modern-timeline-preview">
                              <p class="m-3">
                                Invoice is generated and sent to client services
                              </p>
                            </div>
                            <div class="text-center modern-timeline-body">
                              <h4 class="mb-4">Medical Services</h4>
                              <p class="mb-4">
                                Generate the invoice based on costing received
                                from vendors on the CRM and Save. Once that is
                                done a copy of the request is sent to client
                                services by mail.
                              </p>
                              <p>
                                <a
                                  href="javascript:void(0);"
                                  class="mt-2 btn btn-primary"
                                  >Step 2</a
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="modern-timeline-badge"></div>
                          <div class="modern-timeline-panel">
                            <div class="text-center modern-timeline-preview">
                              <p class="m-3">
                                Message is sent to sponsor and sponsor responds
                                with either proceed or they would handle
                                themselves.
                              </p>
                            </div>
                            <div class="text-center modern-timeline-body">
                              <h4 class="mb-4">Client Services</h4>
                              <p class="mb-4">
                                Client services receives the breakdown of the
                                costs of the medical request by email. Copies
                                the content and sends as a message to the
                                sponsor or places a call to the sponsor to find
                                out if we can proceed with the medical request
                                or not.
                              </p>
                              <p>
                                <a
                                  href="javascript:void(0);"
                                  class="mt-2 btn btn-primary"
                                  >Step 3</a
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="modern-timeline-badge"></div>
                          <div class="modern-timeline-panel">
                            <div class="text-center modern-timeline-preview">
                              <p class="m-3">
                                Based on sponsor's response - confirm proceed or
                                sponsor would handle on CRM
                              </p>
                            </div>
                            <div class="text-center modern-timeline-body">
                              <h4 class="mb-4">Client Services</h4>
                              <p class="mb-4">
                                If the sponsor decides to proceed, make a new
                                call entry under ‘payments’, select the invoice
                                amount from the dropdown and select the status
                                of ‘proceed’ and an email is sent to medical
                                services to alert them to proceed and an invoice
                                is automatically generated and sent to the
                                sponsor while informing accounts so they are
                                aware an invoice has been sent and begin to
                                monitor to determine when payment is received.
                              </p>
                              <p>
                                <a
                                  href="javascript:void(0);"
                                  class="mt-2 btn btn-primary"
                                  >Step 4</a
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="modern-timeline-badge"></div>
                          <div class="modern-timeline-panel">
                            <div class="text-center modern-timeline-preview">
                              <p class="m-3">Payment Confirmation</p>
                            </div>
                            <div class="text-center modern-timeline-body">
                              <h4 class="mb-4">Accounts</h4>
                              <p class="mb-4">
                                As soon as payment is recieved, go ahead and
                                change the status of the medical request to be
                                ‘paid’ or ‘not paid’ as the case may be. A
                                receipt will be generated and sent to the
                                sponsor if the status of the invoice is paid.
                              </p>
                              <p>
                                <a
                                  href="javascript:void(0);"
                                  class="mt-2 btn btn-primary"
                                  >Step 5</a
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="modern-timeline-badge"></div>
                          <div class="modern-timeline-panel">
                            <div class="text-center modern-timeline-preview">
                              <p class="m-3">
                                Medical Request(s) are delivered
                              </p>
                            </div>
                            <div class="text-center modern-timeline-body">
                              <h4 class="mb-4">Medical Services</h4>
                              <p class="mb-4">
                                As soon as the medical request is delivered, go
                                ahead and change the status of the medical
                                request to be ‘delivered’ or ‘not delivered’ as
                                the case may be. If the invoice was linked to a
                                visit, then the linked visit cycle will be
                                marked as completed. Alternatively, you can go
                                ahead to close the visit cycle from the visit
                                report.
                              </p>
                              <p>
                                <a
                                  href="javascript:void(0);"
                                  class="mt-2 btn btn-primary"
                                  >Step 6</a
                                >
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="position-static">
                          <div class="modern-timeline-top"></div>
                        </li>
                        <li class="position-static">
                          <div class="modern-timeline-bottom"></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-dark" data-dismiss="modal">
                  <i class="flaticon-cancel-12"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="RemarkModal"
      role="dialog"
      aria-labelledby="remarkModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="remarkModalLabel">Add Remark</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="remark-type">Remark Types</label>
                  <select class="form-control" v-model="types">
                    <option value="" selected>-- select type --</option>
                    <option value="missed visits">Missed Visits</option>
                    <option value="report submission">Report Submission</option>
                    <option value="medical report">Medical Report</option>
                    <option value="subscriptions">Subscriptions</option>
                    <option value="doctor onboarding">Doctor Onboarding</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="remark">Remark</label>
                  <textarea
                    class="form-control"
                    name="remark"
                    id="remark"
                    cols="30"
                    rows="10"
                    v-model="remark"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="storeRemarks()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import axios from "axios";
import $ from "jquery";

var moment = require("moment");

export default {
  name: "Home",
  components: {},

  computed: {
    ...mapGetters(["url", "user", "gcurl", "michurl"]),

    targetPercent: function () {
      return Math.round((this.visitsThisMonth / 500) * 100);
    },
  },

  mounted() {
    this.lastYearDate = this.lastYearDate = moment()
      .subtract(1, "year")
      .format("YYYY-MM-DD");
    this.loadingCRM = true;
    this.loadingGERO = true;
    this.loadingMICH = true;
    this.fetchDataFromCrm();
    this.fetchDataFromGeroCare();
    this.fetchDataFromMich();
    this.fetchMissedVisits();
    this.fetchMedicalServiceLogApp();
    this.fetchPrescriptionYesterday();

    setTimeout(() => {
      var btn = document.getElementById("downloadupcomingprojects");
      btn.onclick = function () {
        document
          .getElementById("upcomingProjectsWrapper")
          .classList.remove("upcomingproj");
        document.querySelectorAll(".meta-date").forEach((x) => {
          x.style.display = "none";
        });

        domtoimage // eslint-disable-line
          .toBlob(document.getElementById("firstRow"))
          .then(function (blob) {
            window.saveAs(blob, "upcomingprojects.png");
            setTimeout(() => {
              document
                .getElementById("upcomingProjectsWrapper")
                .classList.add("upcomingproj");
              document.querySelectorAll(".meta-date").forEach((x) => {
                x.style.display = "";
              });
            }, 2000);
          });
      };

      // var btnCtd = document.getElementById('downloadongoingprojects');
      // btnCtd.onclick = function() {
      //     domtoimage.toBlob(document.getElementById('ongoingprojectsctd'))
      //     .then(function(blob) {
      //       window.saveAs(blob, 'ongoingprojectsctd.png');
      //     });
      // }
    }, 4000);
  },

  data() {
    return {
      loadingCRM: true,
      loadingMICH: true,
      loadingGERO: true,

      currentYear: moment().format("YYYY"),
      currentMonth: moment().format("MMMM"),
      today: moment().format("YYYY-MM-DD"),
      yesterday: moment().subtract(1, "days").format("YYYY-MM-DD"),
      lastYearDate: "",
      visitsThisMonth: 0,
      missedVisits: 0,
      dailyLog: "",

      yesterdaysPrescription: 0,
      gerocareDailyLog: 0,
      types: "",
      remark: "",

      filterUrl: "",

      clients: [],
      hygeiaCount: 0,
      michCount: 0,
      patientsCount: 0,
      doctorsCount: 0,

      recentPaidPrescriptions: [],
      recentUnpaidPrescriptions: [],

      pdfToView: "",

      //visit cycle
      cycleseries: [],
      cyclechartOptions: {},

      //Visit Reports
      areaseries: [],
      areachartOptions: {},

      //all-time visits summary
      polarseries: [],
      polarchartOptions: {
        chart: {
          type: "polarArea",
        },
        labels: ["Completed", "Completed (couple)", "No Visit"],
        colors: ["#049a58", "#1139d2", "#ef3507"],
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      //add elderly
      addElderlyCallsseries: [],
      addElderlyCallschartOptions: {
        chart: {
          type: "donut",
        },
        labels: ["Responded", "Unreachable", "Retry", "Declined"],
        colors: ["#049a58", "#1139d2", "#e3e607", "#ef3507"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      //call to prospect
      callToProspectseries: [],
      callToProspectchartOptions: {
        chart: {
          type: "donut",
        },
        labels: ["Responded", "Unreachable", "Retry", "Declined"],
        colors: ["#049a58", "#1139d2", "#e3e607", "#ef3507"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      //call for expired sub
      callForExpiredSubseries: [],
      callForExpiredSubchartOptions: {
        chart: {
          type: "donut",
        },
        labels: ["Responded", "Unreachable", "Retry", "Declined"],
        colors: ["#049a58", "#1139d2", "#e3e607", "#ef3507"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      //medical requests
      barseries: [],
      barchartOptions: {},
    };
  },

  methods: {
    fetchDataFromCrm() {
      http
        .get(endpoints.DASHBOARD_DATA)
        .then((response) => {
          this.polarseries = response.allTimeMonthlyVisitsSummary;
          this.areaseries = response.monthlyVisits;
          this.addElderlyCallsseries = response.callsToAddElderly;
          this.callToProspectseries = response.callsToProspects;
          this.callForExpiredSubseries = response.callsToRenewSub;
          this.barseries = response.prescriptions;
          this.recentPaidPrescriptions = response.recentPaidPrescriptions;
          this.recentUnpaidPrescriptions = response.recentUnpaidPrescriptions;

          this.cycleseries = response.visitCycleSeries;

          setTimeout(() => {
            this.loadingCRM = false;
          }, 3000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    storeRemarks() {
      if (!this.types || !this.remark) {
        this.$toast.error(
          "You need to provide both type and remark to continue"
        );
      }
      http
        .post(endpoints.SAVE_DAILY_REMARK, {
          type: this.types,
          remark: this.remark,
        })
        .then(() => {
          this.type = "";
          this.remark = "";
          $("#RemarkModal").modal("hide");

          this.$toast.success("Remark saved successfully");
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    fetchMissedVisits() {
      let filter = `?filter[missed_visits_from]=${this.lastYearDate}&=&filter[missed_visits_to]=${this.today}`;

      gerohttp
        .get(geroendpoints.FETCH_VISIT_TRACKER + filter)
        .then((response) => {
          this.missedVisits = response.total;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });

      this.filterUrl = filter;
    },

    fetchMedicalServiceLogApp() {
      gerohttp
        .get(geroendpoints.DAILY_LOG + `?date=${this.dailyLog}`)
        .then((response) => {
          this.gerocareDailyLog = response.data;
          setTimeout(() => {
            this.loadingGERO = false;
          }, 3000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    fetchPrescriptionYesterday() {
      http
        .get(endpoints.YESTERDAY_PRESCRIPTIONS)
        .then((response) => {
          this.yesterdaysPrescription = response.data;
          setTimeout(() => {
            this.loadingGERO = false;
          }, 3000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    fetchDataFromGeroCare() {
      gerohttp
        .get(geroendpoints.DASHBOARD_DATA)
        .then((response) => {
          this.areachartOptions = {
            chart: {
              height: 500,
              type: "area",
            },
            colors: ["#049a58", "#1139d2", "#ef3507"],
            dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5,
            },
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              categories: response.categories,
            },
          };

          this.barchartOptions = {
            chart: {
              type: "bar",
              height: 350,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            colors: ["#049a58", "#1139d2", "#e3e607", "#ef3507"],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            yaxis: {
              title: {
                text: "number of medical requests",
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val;
                },
              },
            },
            xaxis: {
              categories: response.categories,
            },
          };

          this.cyclechartOptions = {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            colors: ["#049a58", "#ef3507"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                borderRadius: 8,
                horizontal: false,
              },
            },
            xaxis: {
              categories: response.categories,
            },
            // legend: {
            //   position: 'right',
            //   offsetY: 40
            // },
            fill: {
              opacity: 1,
            },
          };

          this.clients = response.corporateClients;
          this.hygeiaCount = response.hygeia;
          this.patientsCount = response.patientsCount;
          this.doctorsCount = response.doctorsCount;

          this.visitsThisMonth =
            response.payments[0].data[11] +
            response.payments[1].data[11] +
            response.payments[2].data[11] +
            response.payments[3].data[11];

          setTimeout(() => {
            this.loadingGERO = false;
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    fetchDataFromMich() {
      axios
        .get(`${this.michurl}/api/crm/benficiaries/count/G3r0car3@17`)
        .then((response) => {
          this.michCount = response.data;
          setTimeout(() => {
            this.loadingMICH = false;
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style>
.badge {
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
}

.upcomingproj {
  height: 40vh !important;
  overflow: scroll;
}
</style>
